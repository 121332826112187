@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import '@angular/material/theming';

@include mat-core();

$app-primary: mat-palette($mat-blue, 500);
$app-accent: mat-palette($mat-light-blue, 500);
$app-warn: mat-palette($mat-red);
$app-theme: mat-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: mat-typography-config(),
    density: 0,
  )
);
$app-theme-dark: mat-dark-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

@include angular-material-theme($app-theme);

.firebase-emulator-warning {
  display: none;
}

ion-content {
  --keyboard-offset: 0 !important;
}

/* ion range során eltűnik a scrollbar fix */
ion-content::part(scroll) {
  overflow-y: auto !important;
}

ion-select.arr-end::part(container) {
  width: 100%;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

ion-header {
  transition: all 275ms;
}

ion-button {
  --box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  margin: 2px;
  -webkit-text-fill-color: var(--ion-color-primary);
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.alert-center .alert-wrapper {
  justify-content: center;
}

.dark {
  color: var(--ion-color-dark) !important;
}

.bg {
  --background: var(--ion-color-bg);
}

.color-primary {
  --color: var(--ion-color-primary);
}

.flex {
  display: flex;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.ion-no-margin {
  h2,
  h3,
  p {
    margin: 0;
  }
}

.thin-modal,
.thin-tall-modal,
.thin-medium-modal,
.thin-short-modal {
  --width: 95vw;
  --height: 95vh;
  --border-radius: var(--ion-border-radius);
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  ion-header ion-toolbar {
    padding-top: 0px !important;
  }
  ion-footer.footer-toolbar-padding ion-toolbar {
    padding-bottom: 0px !important;
  }
}

.thin-short-modal {
  --height: 40vh;
  --min-height: 200px;
}

.thin-medium-modal {
  --height: 50vh;
  --min-height: 400px;
}

@media (min-width: 768px) {
  .thin-modal,
  .thin-short-modal,
  .thin-medium-modal,
  .thin-tall-modal {
    --min-width: 400px;
    --width: 30vw;
    --height: 40vh;
  }

  .thin-medium-modal {
    --height: 50vh;
  }

  .thin-tall-modal {
    --height: 70vh;
  }
}

.no-events {
  pointer-events: none;
}

.disable-validation-borders {
  /* --border-color: var(--ion-border-color);
  --highlight-color: var(--ion-border-color); */
  --highlight-color-valid: var(--ion-border-color);
  --highlight-color-invalid: var(--ion-border-color);
  --highlight-color-focused: var(--ion-border-color);
}

.white-pre-wrap {
  white-space: pre-wrap;
}

.border-radius {
  border-radius: var(--ion-border-radius);
}
